import React from "react";
import Navbar from "../../components/navbar";
import { Carousel } from "antd";
import skill1 from "../../assets/skill1.png";
import skill2 from "../../assets/skill2.png";
import skill3 from "../../assets/skill3.png";
import CourseCard from "../../components/course-card";
import banner1 from "../../assets/banner1.png";
import banner2 from "../../assets/banner2.png";
import banner3 from "../../assets/banner3.png";

const Home = () => {

  const skills = [
    {
      key: 1,
      img: skill1,
      name: "Interactive lessons",
      desc: "Actively immerse yourself in the learning material with over 50 interactive features, including case studies, bookmarks, flashcards, labs, labeling and matching activities, and much more.",
    },
    {
      key: 2,
      img: skill2,
      name: "Hands-on Labs",
      desc: "Put your theoretical knowledge into practice by working in cloud-based simulated, virtual, and sandbox environments, allowing you to develop practical skills for real-world challenges.",
    },
    {
      key: 3,
      img: skill3,
      name: "Industry Certifications",
      desc: "Enroll in our IT training courses to prepare for industry-recognized certifications from leading vendors like Microsoft, PMI, CIW, and more.",
    },
  ];

  const courses = [
    {
      key: 1,
      tag: 'Beginner',
      name: "CompTIA Network+ (N10-008)",
    },
    {
      key: 2,
      tag: 'Advanced',
      name: "CASP+ CompTIA Advanced Security Practitioner (CAS-004)",
    },
    {
      key: 3,
      tag: 'Intermediate',
      name: "CompTIA Cloud+ (CV0-004)",
    },
    {
      key: 4,
      tag: 'Advanced',
      name: "CompTIA Security+ (SY0-701)",
    },
    {
      key: 5,
      tag: 'Beginner',
      name: "CompTIA CYSA+ (CS0-003)",
    },
    {
      key: 6,
      tag: 'Intermediate',
      name: "CompTIA Linux+ (XK0-005)",
    },
  ];

  return (
    <div>
      <Navbar />
      <div className="w-2/3 mx-auto">
        <Carousel autoplay>
          <div>
            <img src={banner1} alt="" />
          </div>
          <div>
            <img src={banner2} alt="" />
          </div>
          <div>
            <img src={banner3} alt="" />
          </div>
        </Carousel>
        <div className="my-20">
          <h1 className="text-4xl">
            Level up your <span className="italic font-semibold">skills</span>
          </h1>
          <div className="mt-10 flex justify-between items-start gap-14">
            {skills.map((item) => (
              <div key={item.key} className="space-y-3">
                <img src={item.img} alt="" />
                <h2 className="text-xl font-medium">{item.name}</h2>
                <p>{item.desc}</p>
              </div>
            ))}
          </div>
        </div>
        <div className="my-20">
          <h1 className="text-4xl">
            Explore our best{" "}
            <span className="italic font-semibold">CompTIA Courses</span>
          </h1>
          <div className="mt-10 grid grid-cols-3 gap-8">
            {courses.map((item) => (
              <CourseCard key={item.key} tag={item.tag} name={item.name} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
