import React from "react";
import Navbar from "../../components/navbar";
import { Carousel } from "antd";
import CourseCard from "../../components/course-card";
import banner1 from "../../assets/banner1.png";

const MyLearings = () => {

  const courses = [
    {
      key: 1,
      tag: 'Beginner',
      name: "CompTIA CYSA+ (CS0-003)",
    },
    {
      key: 2,
      tag: 'Advanced',
      name: "CompTIA Linux+ (XK0-005)",
    },
    {
      key: 3,
      tag: 'Intermediate',
      name: "CompTIA Cloud+ (CV0-004)",
    },
  ];

  return (
    <div>
      <Navbar />
      <div className="w-2/3 mx-auto">
        <Carousel autoplay>
          <div>
            <img src={banner1} alt="" />
          </div>
        </Carousel>
        <div className="my-20">
          <h1 className="text-4xl">
            My <span className="italic font-semibold">Courses</span>
          </h1>
          <div className="mt-10 grid grid-cols-3 gap-8">
            {courses.map((item) => (
              <CourseCard key={item.key} tag={item.tag} name={item.name} bougth={true} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default MyLearings;
