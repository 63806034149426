import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/home';
import MyLearings from './pages/my-learnings';
import Lab from './pages/lab';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/my-courses" element={<MyLearings />} />
        <Route path="/lab" element={<Lab />} />
      </Routes>
    </Router>
  );
}

export default App;
 