import React from 'react'
import logo from '../../assets/logo.png'
import { useNavigate } from 'react-router-dom'

const Navbar = () => {
  const navigate = useNavigate();

  return (
    <div className='border-b p-5 px-8 drop-shadow-sm bg-blue-500 flex justify-between items-center text-white'>
        <img src={logo} alt="" onClick={() => navigate('/')} />
        <div className='space-x-5'>
            <button className='hover:underline' onClick={() => navigate('/')}>Home</button>
            <button className='hover:underline'>Courses</button>
            <button className='hover:underline'>About</button>
            <button className='hover:underline'>Contact Us</button>
        </div>
        <button className='hover:underline' onClick={() => navigate('/my-courses')}>My Learnings</button>
    </div>
  )
}

export default Navbar