import { Button } from "antd";
import React from "react";
import { BsFileEarmarkCode } from "react-icons/bs";
import { IoBriefcaseOutline } from "react-icons/io5";
import { PiProjectorScreenBold } from "react-icons/pi";
import { TbDeviceLaptop } from "react-icons/tb";
import { useNavigate } from "react-router-dom";

const CourseCard = ({ key, tag, name, bougth }) => {
  const navigate = useNavigate();

  return (
    <div key={key} className="w-full border border-black rounded-xl">
      <div className="bg-[#FFD629] rounded-t-xl p-5 min-h-36 relative">
        <h3 className="bg-white p-1.5 px-3 text-sm rounded-full w-fit font-medium">
          {tag}
        </h3>
        <h1 className="text-lg font-semibold mt-3">{name}</h1>
        {bougth && <div className="absolute bottom-0 left-0 w-1/2 bg-black p-1"></div>}
      </div>
      <div className="border-b border-black"></div>
      <div className="p-5">
        <div className="grid grid-cols-2 gap-2">
          <div className="flex items-center gap-3">
            <TbDeviceLaptop className="text-[#757575] text-xl" />
            <h3 className="text-base font-medium">21 Lessons</h3>
          </div>
          <div className="flex items-center gap-3">
            <BsFileEarmarkCode className="text-[#757575] text-xl" />
            <h3 className="text-base font-medium">90+ Assessments</h3>
          </div>
          <div className="flex items-center gap-3">
            <PiProjectorScreenBold className="text-[#757575] text-xl" />
            <h3 className="text-base font-medium">Hands-on Labs</h3>
          </div>
          <div className="flex items-center gap-3">
            <IoBriefcaseOutline className="text-[#757575] text-xl" />
            <h3 className="text-base font-medium">AI tutor (Add on)</h3>
          </div>
        </div>
      </div>
      <div className="border-b border-black"></div>
      {!bougth ? <div className="p-5 flex justify-between items-center">
        <h2 className="text-xl font-semibold">$419.99</h2>
        <Button
          type="primary"
          className="text-sm font-medium p-5 px-10 uppercase"
        >
          Buy Now
        </Button>
      </div>
      :
      <div className="p-5 flex gap-5 items-center">
        <Button
          className="text-sm font-medium p-5 px-10 uppercase w-full border-black"
        >
          Lab
        </Button>
        <Button
          type="primary"
          className="text-sm font-medium p-5 px-10 uppercase w-full"
          onClick={() => navigate('/lab')}
        >
          Book
        </Button>
      </div>}
    </div>
  );
};

export default CourseCard;
